<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Информация по посещаемости
          </template>
          <template v-slot:toolbar>
            <b-form-datepicker
                id="example-datepicker"
                v-model="filter.date" class="mb-2"
                @context="changeDate"
                locale="ru"
                start-weekday="1"
                :date-disabled-fn="dateDisabled"></b-form-datepicker>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>Класс</th>
                <th>Общее количество детей</th>
                <th>Факт Напол</th>
                <th>Всего отстуствует</th>
                <th>По болезни</th>
                <th>По другим причинам</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in data.items" :key="k">
                <td>{{item.unitName}}</td>
                <td>{{item.allStudentsCount}}</td>
                <td>{{item.fullness}}</td>
                <td>{{item.totalAbsent}}</td>
                <td>{{item.absentDueToIllness}}</td>
                <td>{{item.absentOtherReason}}</td>
              </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Всего</td>
                  <td>{{data.allStudentsCount}}</td>
                  <td>{{data.fullness}}</td>
                  <td>{{data.totalAbsent}}</td>
                  <td>{{data.absentDueToIllness}}</td>
                  <td>{{data.absentOtherReason}}</td>
                </tr>
              </tfoot>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
export default {
  name: "PreschoolAbsenceLogReport",
  components: {
    KTPortlet
  },
  data() {
    return {
      filter:{
        date: new Date()
      },
      data:{
        items:[]
      }
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0;
    },
    changeDate() {
      this.reloadData();
    },
    reloadData(){
      let $this = this;
      ApiService.postSecured("preschoolAbsenceLog/listForPreSchool", this.filter).then(({data}) => {
        console.log(data);
        $this.data = data;
      }).catch(() => {
        
      });
    }
  }
};
</script>
